import { reactQueryClient } from 'web/App';
import firebase from 'firebase/app';
import 'firebase/auth';
import branch from 'branch-sdk';
import { api } from '.';
import { getToken, signOut as signOutFromFirebase } from 'web/utils/use-firebase';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { SessionUser } from 'web/store/session/types';
import { showToast } from 'web/utils/toast';
import getSignedInCookie from 'web/utils/get-signed-in-cookie';
import { setParamsFromLocalStorage } from 'web/utils/auth/persistParamsThroughSignUp';

export const signInWithToken = async (token: string) => {
  const response = await api.post('/login', { token });
  return response.data;
};

export const useVerifyEmail = () => {
  return useMutation(
    async (oobCode: string) => {
      const response = await api.post<{ custom_token: string }>('/verify-email', {
        oobCode,
      });

      setParamsFromLocalStorage();

      if (response.data.custom_token) {
        await firebase.auth().signOut();
        await firebase.auth().signInWithCustomToken(response.data.custom_token);
      }

      return response.data;
    },
    {
      onSuccess: () => {
        reactQueryClient.invalidateQueries([queryKeys.session]);
      },
    },
  );
};

export const useSession = () => {
  const { data: hasCookieSessionSet } = useHasSignInCookies();

  const query = useQuery(
    [queryKeys.session],
    async () => {
      const response = await api.get<SessionUser>('/me');
      if (response.data.id) {
        branch.setIdentity(response.data.id);
      }
      return response.data;
    },
    {
      enabled: hasCookieSessionSet,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        // We silently fail here because we don't want to show an error message
        return;
      },
    },
  );

  // https://github.com/TanStack/query/issues/3584#issuecomment-1256986636
  return {
    ...query,
    isLoading: query.isLoading && query.fetchStatus !== 'idle',
  };
};

export const useHasSignInCookies = () => {
  return useQuery([queryKeys.session, 'cookies'], async () => {
    return getSignedInCookie();
  });
};

export const signOutSession = () => {
  return api.post('/logout');
};

export const signOut = async () => {
  await signOutSession();
  await signOutFromFirebase();
  reactQueryClient.invalidateQueries();
  reactQueryClient.clear();
};

export const deleteAccount = async () => {
  const token = await getToken(true);

  await api.delete('/me', { data: { token } });
  await signOut();
};

export const useDeleteAccount = () => {
  return useMutation(
    async () => {
      const token = await getToken(true);
      return api.delete('/me', { data: { token } });
    },
    {
      onSuccess: async () => {
        await signOutFromFirebase();
        reactQueryClient.clear();
        showToast({
          message: 'Your account has been successfully deleted',
          type: 'success',
        });
      },
    },
  );
};

export const useSignOutSession = () => {
  return useMutation(() => signOut(), {
    onSuccess: () => {
      reactQueryClient.invalidateQueries([queryKeys.session]);
    },
  });
};

export const useCustomToken = () => {
  return useMutation(async (idToken: string) => {
    const response = await api.post('/custom-token', {
      id_token: idToken,
    });
    return response.data;
  });
};
