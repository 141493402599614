import React, { Suspense, lazy, useEffect } from 'react';
import 'regenerator-runtime';
import { Provider as StoreProvider, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { IKContext } from 'imagekitio-react';
import branch from 'branch-sdk';
import 'web/utils/init-firebase';

import { getLcn } from 'web/utils/labeled-classnames';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; // only included when process.env.NODE_ENV === 'development'
import useFirebase from './utils/use-firebase';
import { getCurrentColorModeKey } from './utils/color-mode';
import store from './store';
import { setColorModeByKey } from './store/settings/slice';
import useWindowsOSStyles from './utils/use-windows-os-styles';
import { IS_E2E_TEST } from './utils/general';
import LoadingSpinner from './components/LoadingSpinner';
import { BRANCH_API_KEY, IMAGEKIT_BASE_URL } from './utils/constants';
import { useUserGtag } from './utils/analytics/google-analytics';
import { initializeGTM } from './utils/analytics/google-tag-manager/init-gts';

import { useInitFeatureFlags } from './store/feature-flags/slice';
import HeaderMeta from 'shared/components/HeaderMeta';

import 'react-loading-skeleton/dist/skeleton.css';
import useLiveNotifications from './utils/use-live-notifications';
import { showToast } from './utils/toast';
import { Dresser } from './components/Dresser';

const Router = lazy(() => import('./routers/Router'));

initializeGTM();

// client for react-query
export const reactQueryClient = new QueryClient({
  queryCache: new QueryCache({
    // since many RQ queries have auto retry, this is the only place to have a singe UI
    // effect for queries that fail.
    // See: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#a-bad-api
    // Requires the use of
    //   meta: {
    //     errorMessage: 'Failed to do shit',
    //   },
    onError: (error, query) => {
      if (query?.meta?.errorMessage) {
        showToast({
          message: `${query?.meta?.errorMessage}`,
          type: 'error',
        });
      }
    },
  }),
});

const App = () => {
  useInitFeatureFlags();
  useFirebase();
  useUserGtag();
  useLiveNotifications();

  useEffect(() => {
    if (BRANCH_API_KEY) {
      branch.init(BRANCH_API_KEY, undefined, (_err, data) => {
        // TODO: Use data.data_parsed.user_id
      });
    }
  }, []);

  const dispatch = useDispatch();

  useWindowsOSStyles();

  const lcn = getLcn({
    reactAppContainer: ['bg-scene antialiased relative z-0'],
  });

  useEffect(() => {
    const colorModeKey = getCurrentColorModeKey();

    if (colorModeKey) {
      dispatch(setColorModeByKey(colorModeKey));
    } else {
      dispatch(setColorModeByKey('system'));
    }
  }, []);

  return (
    <IKContext urlEndpoint={IMAGEKIT_BASE_URL}>
      <div className={lcn.reactAppContainer}>
        <Suspense fallback={<LoadingSpinner />}>
          <HeaderMeta />
          <Router />
        </Suspense>
        <Toaster
          position="top-center"
          toastOptions={{
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          }}
        />
      </div>
      <div id="modal-root" />
      <Dresser />
    </IKContext>
  );
};

export default () => (
  <StoreProvider store={store}>
    <QueryClientProvider client={reactQueryClient}>
      <App />
      {!IS_E2E_TEST && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
    </QueryClientProvider>
  </StoreProvider>
);
