import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { isProduction } from 'web/utils/constants';
import store, { RootState } from 'web/store';
import * as CircleHomeActions from './actions/circle-home';
import * as GlobalNav from './actions/global-nav';
import * as ReaderActions from './actions/reader';
import * as SearchActions from './actions/search';
import * as SettingsActions from './actions/settings';
import * as UserProfileActions from './actions/user-profile';
import * as DocumentProfileActions from './actions/document-profile';
import * as SessionActions from './actions/session';
import { useSession } from 'web/api/session';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFirstSignIn } from 'web/store/firebase/slice';
import trackError from 'shared/utils/errors/track-error';
import { setUserInGTM } from './google-tag-manager/init-gts';

export const Actions = {
  circleHome: CircleHomeActions,
  globalNav: GlobalNav,
  reader: ReaderActions,
  search: SearchActions,
  settings: SettingsActions,
  userProfile: UserProfileActions,
  documentProfile: DocumentProfileActions,
  session: SessionActions,
};

// https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtag
// https://github.com/codler/react-ga4/issues/7
// sets the user_id for the analytics, once it's set
export const useUserGtag = () => {
  const { data: sessionUser } = useSession();
  const dispatch = useDispatch();
  const { isFirstSignIn } = useSelector((state: RootState) => state.firebase);

  useEffect(() => {
    if (sessionUser && isProduction) {
      setUserInGTM(sessionUser.id);

      ReactGA.set({ user_id: sessionUser.id });

      SessionActions.userSignedIn();

      if (isFirstSignIn) {
        SessionActions.userSignedUp();
        dispatch(setIsFirstSignIn(false));
      }
    }
  }, [sessionUser]);
};

type SendProps = {
  hitType: string;
  title: string;
};

export const send = async (props: SendProps) => {
  if (isProduction) {
    try {
      await ReactGA.send(props);
    } catch (e) {
      trackError(e);
    }
  }
};

interface EventProps extends UaEventOptions {
  title: string;
}

const event = async (key: string, props: EventProps) => {
  if (isProduction && !currentUserIsThreadableStaff()) {
    try {
      await ReactGA.event(key, props);
    } catch (e) {
      trackError(e);
    }
  }
};

const currentUserIsThreadableStaff = () => {
  const email = store.getState().firebase?.firebaseUser?.email || '';
  return email.includes('@threadablebooks');
};

type UserInteractionProps = {
  action: string;
  label: string;
  title: string;
};

export const userInteraction = async (key: string, props: UserInteractionProps) => {
  event(key, {
    ...props,
    category: 'user_interaction',
    nonInteraction: false,
  });
};

type UserButtonClicksProps = {
  label: string;
  to?: string;
  from?: string;
  title: string;
};

export const userButtonClick = async (props: UserButtonClicksProps) => {
  if (isProduction) {
    try {
      await ReactGA.send({ hitType: 'button_click', ...props });
    } catch (e) {
      trackError(e);
    }
  }
};
